// loading and working states
export const SET_WORKING = 'SET_WORKING'
export const SET_LOADING = 'SET_LOADING'

// REST API version
export const SET_API_VERSION = 'SET_API_VERSION'

// auth
export const SET_JWT = 'SET_JWT'

// links list
export const SET_LINKS = 'SET_LINKS'
