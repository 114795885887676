import app from './app'
import user from './user'
import dcloud from './dcloud'
import endpoints from './endpoints'

export default {
  app,
  user,
  dcloud,
  endpoints
}
